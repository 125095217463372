import React, { useContext } from "react"
import { Footer, LinkList, AvenueLogoLink, LogosContainer } from "./style"
import { PopUpContext } from "context/PopUpContext.js"
import { ReactComponent as AvenueLogo } from "images/icons/made-by-avenue@2x.svg"
import MDASilver from "images/mda-silver.png"

const FooterLinks = () => {
  const { togglePopUp, isPopUpOpen, popUpType } = useContext(PopUpContext)
  const acknowledgementContent = `
    <div>
        <h3>Acknowledgements</h3>
        <p>This website was funded by a University of Melbourne Student Services & Amenities Fee Grant. It was developed by a team of experts from across the University, led by Dr Laura Tarzia. We gratefully acknowledge the contribution of the students who gave up their time to review and input into the design and development of the site. We also acknowledge contributions from CASA House.</p>

        <p>The "winding down" exercise and the Healthy Communication exercise were adapted from "Engaging with Perpetrators of Domestic Violence: Techniques for Early Intervention" by Kate Iwi and Chris Newman (Jessica Kingsley Publishers 2015).</p>
    </div>
    `

  const privacyContent = `
    <div>
        <h3>Privacy Statement</h3>
        <p>MySafety has been designed to provide as much anonymity as possible for its users. It does not collect any identifying information about you. Your answers to the questions within the website cannot be linked to you personally. The site does not record your IP address or use cookies.</p>
    </div>
    `

  return (
    <Footer>
      <LinkList>
        <a
          href="#"
          onClick={() =>
            togglePopUp(true, "longForm", null, acknowledgementContent)
          }
        >
          Acknowledgements
        </a>
        <a
          href="#"
          onClick={() => togglePopUp(true, "longForm", null, privacyContent)}
        >
          Privacy Policy
        </a>
      </LinkList>
      <LogosContainer>
        <img src={MDASilver} alt="" />
        <AvenueLogoLink to="https://avenue.design/">
          <AvenueLogo />
        </AvenueLogoLink>
      </LogosContainer>
    </Footer>
  )
}

export default FooterLinks
